import {
  Box,
  Container,
  Title,
  createStyles,
  TypographyStylesProvider,
  Text,
  List,
  Flex,
  Divider,
} from "@mantine/core";
import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { IconMail, IconPhoneCall } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  pageTitle: {
    fontSize: "3rem",
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#b28a4f",
      transition: "width 0.3s ease-in-out",
      width: 100,
      height: 2,
      marginBottom: theme.spacing.xs,
    },
    ".mantine-Paper-root:hover &::after": {
      width: "100%",
    },
  },
}));

const KontaktPage = ({ data }) => {
  const { classes } = useStyles();

  return (
    <Layout>
      <Box mt={56} mb={56} w="100%">
        <Helmet titleTemplate="%s | Kontakt" />
        <Container py="xl" size="lg" h="100vh">
          <Title
            className={classes.pageTitle}
            order={1}
            color="rgba(50, 59, 60, 0.8)"
            ff="Oswald"
            ml="sm"
          >
            Kontakt
          </Title>
          <Box px="sm" sx={{overflowY: "auto", overflowX: "hidden"}}>
            <TypographyStylesProvider mt={30}>
              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
            </TypographyStylesProvider>
            {data?.markdownRemark?.frontmatter &&
              (data.markdownRemark.frontmatter.phoneContacts?.length > 0 ||
                data.markdownRemark.frontmatter.emailContacts?.length > 0) && (
                  <>
                    <Title order={3} mt={50}>
                      Unsere Kontaktdaten
                    </Title>
                    <List mt={20} w="100%" mx={5}>
                      {data?.markdownRemark?.frontmatter?.phoneContacts?.map(
                        (contact, i) => (
                          <>
                            <Flex
                              direction={"row"}
                              align="start"
                              gap={10}
                              justify={"space-between"}
                              key={"phone" + i}
                            >
                              <List.Item
                                style={{ flex: 1 }}
                                icon={<IconPhoneCall size={24} color="gray" />}
                                color="gray"
                              >
                                <Text size="sm" color="dark">
                                  {contact.name}
                                </Text>
                              </List.Item>
                              <Text style={{ flex: 1 }} size="sm" color="gray">
                                {contact.phone}
                              </Text>
                            </Flex>
                            <Divider mb="sm" key={"phone_divider" + i}/>
                          </>
                        )
                      )}
                      {data?.markdownRemark?.frontmatter?.emailContacts?.map(
                        (contact, i) => (
                          <>
                            <Flex
                              direction={"row"}
                              align="start"
                              gap={10}
                              justify={"space-between"}
                              key={"email" + i}
                            >
                              <List.Item
                                style={{ flex: 1 }}
                                icon={<IconMail size={24} color="gray" />}
                                color="gray"
                              >
                                <Text size="sm" color="dark">
                                  {contact.name}
                                </Text>
                              </List.Item>
                              <Text style={{ flex: 1 }} size="sm" color="gray">
                                {contact.email}
                              </Text>
                            </Flex>
                            <Divider mb="sm" key={"email_divider" + i}/>
                          </>
                        )
                      )}
                    </List>
                  </>
              )}
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

KontaktPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default KontaktPage;

export const aboutPageQuery = graphql`
  query KontaktPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        phoneContacts {
          name
          phone
        }
        emailContacts {
          name
          email
        }
      }
    }
  }
`;
